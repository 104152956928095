let selectors = "a[href*='youtu.be'], a[href*='youtube.com'], a[href*='youtube-nocookie.com'], a[href*='vimeo.com'], a[href$='.gif'], a[href$='.jpg'], a[href$='.png'], a[href*='.gif?'], a[href*='.jpg?'], a[href*='.png?']";
let initAttribute = 'data-lightbox-ready';

//this will be lazyloaded
let getVideoId;

export default {
	init() {
		let items = document.querySelectorAll(selectors);
		if (items.length) {
			import(/*webpackChunkName: "get-video-id"*/ 'get-video-id').then(({default:getVidId}) => {
				getVideoId = getVidId;
				import(/*webpackChunkName: "wa-mediabox"*/ './wa-mediabox').then(() => {
					this.run(items);
				});
			});
		}
	},
	run(items) {
		//clear the galleries
		WAMediaBox.galleries = {};

		let videoTestStrings = ['youtu.be', 'youtube.com', 'youtube-nocookie.com', 'vimeo.com'];

		//assign items to galleries
		let galleries = document.querySelectorAll('data-gallery');
		if (galleries.length) {
			galleries.forEach(gallery => {
				//gallery name
				let galleryName = gallery.getAttribute('data-gallery') || '_';

				//get all of the videos in this gallery
				let items = gallery.querySelectorAll(selectors);
				items.forEach(item => {
					item.setAttribute('data-gallery-name', galleryName);
				});
			});
		}

		//set up the gallery items
		items.forEach(item => {
			if (!item.classList.contains('no-lightbox') && !item.hasAttribute(initAttribute)) {
				//add the init attribute
				item.setAttribute(initAttribute, 'y');

				//get the basics
				let galleryName = item.getAttribute('data-gallery-name') || '_';
				let source = item.getAttribute('href');
				let title = item.getAttribute('title');
				if (!title) {
					title = item.getAttribute('data-title');
				}
				let index;

				//determine if this is a video
				let isVideo = false;
				videoTestStrings.forEach(videoTestString => {
					if (source.indexOf(videoTestString) > -1) {
						isVideo = true;
					}
				});

				if (!isVideo) {
					let download = item.getAttribute('data-download');
					if (!download) {
						download = source;
					}
					index = WAMediaBox.addImage(galleryName, source, title, download);
				} else { //video
					let width = ( item.hasAttribute("data-width") ? parseInt(item.getAttribute("data-width")) : null );
					let height = ( item.hasAttribute("data-height") ? parseInt(item.getAttribute("data-height")) : null );

					let {id, service} = getVideoId(source);

					if (id) {
						if (service === 'youtube') {
							source = 'https://www.youtube-nocookie.com/embed/'+id+'?feature=oembed&rel=0showinfo=0&modestbranding=1&disablekb=1';
						} else if (service === 'vimeo') {
							source = 'https://player.vimeo.com/video/'+id;
						}
					}

					index = WAMediaBox.addIframe(galleryName, source, title, width, height);
				}

				item.addEventListener("click", function(ev){
					ev.preventDefault();
					ev.stopPropagation();
					ev.cancelBubble = true;

					WAMediaBox.openGallery(galleryName, index);

					return false;
				});
			}
		});
	},
	close() {
		if (typeof(WAMediaBox) !== 'undefined' && WAMediaBox.hasOwnProperty('galleries') && Object.keys(WAMediaBox.galleries).length) {
			for (let galleryName of Object.keys(WAMediaBox.galleries)) {
				let gallery = WAMediaBox.galleries[galleryName];
				gallery.close();
			}
		}
	}
}
