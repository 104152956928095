import simpleStorage from 'simplestorage.js';

//set up the delay
let delay = 4000;

export default {
	init({
		selector = '[data-banner]',
		closeSelector = '[data-banner-close]'
	     } = {}) {

		let banners = document.querySelectorAll(selector);

		if (banners && banners.length) {
			banners.forEach(banner => {

				let bannerName = banner.getAttribute('data-banner');

				if (bannerName) {

					if (simpleStorage.hasKey(bannerName)) {
						//remove the banner
						banner.parentNode.removeChild(banner);
					} else {
						setTimeout(()=>{
							//show the banner
							banner.classList.remove('hidden');
						}, delay);
					}

					//close button
					let closeButton = banner.querySelector(closeSelector);
					if (closeButton) {
						//add click listener
						closeButton.addEventListener('click', e => {
							e.preventDefault();

							//set storage key
							simpleStorage.set(bannerName, 'clicked', {TTL: 30*24*60*60*1000});

							//remove the banner
							banner.parentNode.removeChild(banner);
						});
					}
				}
			})
		}
	}
}