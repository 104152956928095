let instances = [];

export default {
	init(selector = '[data-spin]') {
		let spinEls = document.querySelectorAll(selector);
		if (spinEls.length) {
			import(/*webpackChunkName: "spin"*/ './spin360').then(({default:Spin360}) => {
				spinEls.forEach(spinEl => {
					let frames = parseInt(spinEl.getAttribute('data-frames'), 10);
					let width = parseInt(spinEl.getAttribute('data-width'), 10);
					let height = parseInt(spinEl.getAttribute('data-height'), 10);
					let startFrame = parseInt(spinEl.getAttribute('data-start-frame'), 10) || 0;
					let direction = spinEl.getAttribute('data-direction') || 'v';
					let instance = new Spin360(spinEl, frames, width, height, {
						direction,
						startFrame
					});
					instances.push(instance);
				});
			});
		}
	},
	destroy() {
		instances.forEach(instance => {
			if (instance) {
				instance.destroy();
			}
		});
		instances = [];
	}
}