// ---------- Config Setup ----------
const SITE = window.SITE || {}
//const isDevMode = SITE.devMode || false;
//const isProduction = SITE.isProduction || false;
global.isAdminLoggedIn = SITE.isAdminLoggedIn || false
global.mobileMenuMaxWidth = SITE.mobileMenuMaxWidth || 0

// ---------- Are we dealing with IE? ----------
import isIE11 from './Utils/isIE11'

// ---------- Google Maps ----------
window.mapStyles = [
	{
		featureType: 'all',
		elementType: 'all',
		stylers: [
			{
				hue: '#008eff',
			},
		],
	},
	{
		featureType: 'poi.business',
		elementType: 'labels.icon',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'road',
		elementType: 'all',
		stylers: [
			{
				saturation: -70,
			},
		],
	},
	{
		featureType: 'transit',
		elementType: 'all',
		stylers: [
			{
				visibility: 'on',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'all',
		stylers: [
			{
				visibility: 'simplified',
			},
			{
				saturation: -60,
			},
		],
	},
]
window.isGmapsLoaded = false

// ---------- Variables ----------
const scrollSpeed = 1000
const scrollOffset = 121

// ---------- Polyfills ----------

import 'svgxuse'
import 'es7-object-polyfill'

// ---------- debounce ----------
import { debounce } from 'debounce'

// ---------- global PubSub ----------
import PubSub from 'PubSub'
window.sitePubSub = new PubSub()

// ---------- Components ----------

// ---------- js class ----------
import './Components/jsClass'

// ---------- ie11 class ----------
import './Components/ie11Class'

// ---------- lazysizes ----------
import lazySizes from 'lazysizes'
import 'lazysizes/plugins/attrchange/ls.attrchange'
import 'lazysizes/plugins/bgset/ls.bgset'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import 'lazysizes/plugins/respimg/ls.respimg'
Object.assign(lazySizes.cfg, { loadMode: 1 })

// ---------- pjax ----------
import Pjax from 'pjax'
window.pjaxOptions = {
	elements:
		'a[href]:not(.no-pjax):not([href$=".gif"]):not([href$=".jpg"]):not([href$=".png"]):not([href$=".pdf"]):not([href$=".json"])',
	selectors: [
		'head > title',
		'meta[name="description"]',
		'meta[name="csrf-name"]',
		'meta[name="csrf-value"]',
		'.main',
	],
	cacheBust: false,
	analytics: typeof window.pjaxAnalytics !== 'undefined' ? window.pjaxAnalytics : function () {},
}
window.pjax = new Pjax(pjaxOptions)

// ---------- smooth scroll ----------
import SmoothScrollLinks from './Components/smoothScroll'
SmoothScrollLinks.init(scrollSpeed, scrollOffset)

// ---------- scroll indicator ----------
import DownLink from './Components/downLink'
let downLink = new DownLink(
	'<div class="scrollLink-wrapper"><button class="scrollLink"><svg class="icon icon-arrow-down"><use href="#arrow-down"></use></svg></button></div>',
	{ speed: scrollSpeed },
)

// ---------- back to top button ----------
import topLink from './Components/topLink'
topLink.initFromHtml(
	'<div class="topLink-wrapper"><button class="topLink"><svg class="icon icon-arrow-up"><use xlink:href="#arrow-up"></use></svg></button></div>',
	{ speed: scrollSpeed, topDistance: 400 },
)

// ---------- floating header ----------
import floating from './Components/floatingElement'
floating.init()

// ---------- mobile menu ----------
import MobileMenu from './Components/mobileMenu'
const mobileNav = new MobileMenu({
	openClass: 'mobileNavOpen',
	closeClass: 'mobileNavClosed',
	maxWidth: 1024,
	toggleSelector: '[data-mobile-menu-toggle]',
})
mobileNav.close()
// close the nav when a mobile nav links is clicked (for fragment links)
let mobileNavLinks = document.querySelectorAll('.mobileNav a[href*="#"]')
mobileNavLinks.forEach(mobileNavLink => {
	mobileNavLink.addEventListener('click', () => {
		mobileNav.close()
	})
})

// ---------- svg sprite ----------
import './Components/svgSprite'

// ---------- map ----------
import simpleMap from './Components/simpleMaps'

// ---------- sliders ----------
import tinySliders from './Components/tinySliders'
import flickitySliders from './Components/flickitySliders'

// ---------- responsive videos ----------
import resposiveVideos from './Components/responsiveVideos'

// ---------- drawers ----------
import drawers from './Components/drawers'

// ---------- lightbox ----------
import lightbox from './Components/lightbox'

// ---------- aos ----------
import AOS from 'aos'

// ---------- sticky ----------
import sticky from './Components/sticky'
sitePubSub.subscribe('initSticky', debounce(sticky.init, 100, false))
sitePubSub.subscribe('updateSticky', sticky.update)

// ---------- scroll menu ----------
import scrollMenu from './Components/scrollMenu'
scrollMenu.init({ speed: scrollSpeed, offset: scrollOffset })
sitePubSub.subscribe('updateScrollMenu', () => {
	scrollMenu.init({ speed: scrollSpeed, offset: scrollOffset })
})

// ---------- vue apps ----------
import builder from './Components/builder/builder'
import resellers from './Components/resellers'
import dealerForm from './Components/dealer/dealerForm'

// ---------- parallax ----------
import parallax from './Components/parallax'

// ---------- spins ----------
import spins from './Components/spins'

// ---------- scrollbar compensation ----------
import scrollbarCompensation from './Components/scrollbarCompensation'
scrollbarCompensation.init()

// ---------- top bar ----------
import topbar from 'topbar'
topbar.config({
	barColors: { 0: 'rgba(255, 206, 56, 1)', 1: 'rgba(255, 206, 56, 1)' },
	shadowBlur: 0,
	shadowColor: 'rgba(255, 255, 255, 0)',
})

// ---------- cart ----------
import cartIcon from './Components/cart/cartIcon'
cartIcon.init()

// ---------- banner ----------
import banner from './Components/banner'
banner.init()

// ---------- modals ----------
import modals from './Components/modals'
modals()

// ---------- splash ----------
import splash from './Components/splash'
splash.init({
	showOnUnload: window.splashOut,
	displayTime: window.splashTime,
	storageKey: window.splashStorageName,
})

// ---------- product type toggle ----------
import productTypeToggle from './Components/productTypeToggle'
productTypeToggle.init()

// ---------- bricks ----------
import twitterEmbed from './Components/twitterEmbed'
import instagramEmbed from './Components/instagramEmbed'
import facebookEmbed from './Components/facebookEmbed'
import bricks from './Components/bricks'

// ---------- on page load ----------
let navLinks = document.querySelectorAll('.nav a, .mobileNav a')
const onPageLoad = function (isInitialLoad = false) {
	// ---------- nav ----------
	let currentUrl = window.location.href.split('#')[0]
	navLinks.forEach(navLink => {
		if (navLink.href === currentUrl) {
			navLink.classList.add('selected')
		} else if (currentUrl.indexOf(navLink.href) > -1) {
			navLink.classList.add('selected')
		} else {
			navLink.classList.remove('selected')
		}
	})

	// ---------- csrf tokens ----------
	window.csrfTokenName = document.querySelector('meta[name="csrf-name"]').getAttribute('content')
	window.csrfTokenValue = document.querySelector('meta[name="csrf-value"]').getAttribute('content')

	// ---------- scroll indicator ----------
	downLink.reset()

	// ---------- sliders ----------
	if (!isIE11 && !isInitialLoad) {
		tinySliders.destroy()
	}
	tinySliders.init()
	if (!isIE11 && !isInitialLoad) {
		flickitySliders.destroy()
	}
	flickitySliders.init()

	// ---------- aos ----------
	if (isInitialLoad) {
		AOS.init()
	} else {
		AOS.refresh()
	}

	// ---------- sticky ----------
	sitePubSub.publish('initSticky')

	// ---------- scroll menu ----------
	scrollMenu.init({ speed: scrollSpeed, offset: scrollOffset })

	// ---------- bricks ----------
	if (!isInitialLoad) {
		bricks.destroy()
	}
	bricks.init()
	twitterEmbed()
	instagramEmbed()
	facebookEmbed()

	// ---------- responsive videos ----------
	if (!isInitialLoad) {
		resposiveVideos.destroy()
	}
	resposiveVideos.init()

	// ---------- resellers ----------
	if (!isInitialLoad) {
		resellers.destroy()
	}
	resellers.init()

	// ---------- builder ----------
	if (!isInitialLoad) {
		builder.destroy()
	}
	builder.init()

	// ---------- dealer form ----------
	if (!isInitialLoad) {
		dealerForm.destroy()
	}
	dealerForm.init()

	// ---------- map ----------
	if (!isInitialLoad) {
		simpleMap.destroy()
	}
	simpleMap.init()

	// ---------- lightbox ----------
	lightbox.init()

	// ---------- drawers ----------
	drawers.init()

	// ---------- parallax ----------
	if (!isInitialLoad) {
		parallax.destroy()
	}
	parallax.init()

	// ---------- spins ----------
	if (!isInitialLoad) {
		spins.destroy()
	}
	spins.init()

	// ---------- product options ----------
	let currentPath = window.location.pathname
	if (currentPath.indexOf('/products/') === 0) {
		let productBundleSelector = '[data-product-options]'
		let productBundleOptionEls = document.querySelectorAll(productBundleSelector)
		if (productBundleOptionEls.length) {
			import(/* webpackChunkName: "productBundleOptions" */ './Components/productBundleOptions').then(
				({ default: productBundleOptions }) => {
					productBundleOptions.init({ selector: productBundleSelector })
				},
			)
		}
	}
}
onPageLoad(true)

document.addEventListener('pjax:success', () => {
	onPageLoad(false)
})
document.addEventListener('pjax:error', e => {
	console.log('pjax error', e)
	if (e.hasOwnProperty('request') && (e.request.status === 301 || e.request.status === 302)) {
		window.location.replace(e.request.responseURL)
	}
})
document.addEventListener('pjax:send', () => {
	// ---------- nav ----------
	mobileNav.close()

	// ---------- lightbox ----------
	lightbox.close()

	// ---------- topbar ----------
	topbar.show()

	// ---------- sliders ----------
	if (isIE11) {
		tinySliders.destroy()
		flickitySliders.destroy()
	}
})

document.addEventListener('pjax:complete', () => {
	topbar.hide()
})
