import 'classlist.js';
import {debounce} from 'debounce';
import SmoothScroll from 'smooth-scroll';

let initAttribute = 'data-menu-ready';

/**!
 * Scroll Menu
 *
 * Sets up scroll menu tracking.
 *
 * @author Aaron Waldon <aaron@causingeffect.com>
 * @copyright Aaron Waldon 2018
 */
export default {
	init({
		     activeClass = 'active',
		     selector = '[data-scroll-menu]',
		     speed = 300,
		     offset = 0
	     } = {}) {

		let menus = document.querySelectorAll(selector);

		let scroller = new SmoothScroll();

		if (menus && menus.length) {

			menus.forEach(menu => {
				if (!menu.hasAttribute(initAttribute)) {
					menu.setAttribute(initAttribute, '1');

					//closest id
					let lastId = null;

					//is this a select?
					let isSelect = menu.tagName.toLowerCase() === 'select';

					//set up the click events for the select change
					if (isSelect) {
						menu.addEventListener('change', function (e) {

							let el = document.querySelector(this.value);
							if (el) {
								//scroll there
								scroller.animateScroll(el, null, {speed, offset});
							}
						});
					}

					//menu items
					let menuItems = isSelect ? menu.querySelectorAll('option') : menu.querySelectorAll('a');

					//scroll items
					let scrollItems = [];
					if (menuItems && menuItems.length) {
						//only grab the items if the link exists in the page
						menuItems.forEach(menuItem => {
							let href = isSelect ? menuItem.getAttribute('value') : menuItem.getAttribute('href');
							let el = document.querySelector(href);
							if (el) {
								scrollItems.push(el);
							}
						});
					}

					if (scrollItems.length) {
						let scrollHandler = () => {
							let newId = '';
							let lowest = null;

							//scroll items
							scrollItems.forEach(scrollItem => {
								if (scrollItem && scrollItem.offsetParent !== null) {
									let itemPos = scrollItem.getBoundingClientRect().top || 0;
									let absItemPos = Math.abs(itemPos);

									if (lowest == null ) {
										lowest = absItemPos;
									}

									if (absItemPos <= lowest) {
										lowest = absItemPos;
										newId = scrollItem.getAttribute('id');
									}
								}
							});

							if (newId !== lastId) {
								lastId = newId;

								//unselect all of the menu items
								menuItems.forEach(menuItem => {
									if (menuItem) {
										if (isSelect) {
											menuItem.selected = false;
										} else {
											menuItem.classList.remove(activeClass);
										}
									}
								});

								//select the new menu item
								let selectedMenuEl = isSelect ?  menu.querySelector('option[value="#'+newId+'"]') : menu.querySelector('[href="#'+newId+'"]');
								if (selectedMenuEl) {
									if (isSelect) {
										selectedMenuEl.selected = true;
									} else {
										selectedMenuEl.classList.add(activeClass);
									}
								}
							}
						};

						document.addEventListener('scroll', debounce(scrollHandler, 10));
						scrollHandler();
					}
				}
			});
		}
	},
	destroy() {

	}
}