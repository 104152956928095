import ListenerManager from './listenerManager';
import debounce from 'debounce';
let listenerBoss = new ListenerManager();

let initAttribute = 'data-bricks-ready';

//holds the masonry instances
let bricksInstances = [];

//keeps track of subscriptions
let subscriptions = [];

export default {
	init(selector = '[data-bricks]') {
		let bricksEls = document.querySelectorAll(selector);
		if (bricksEls.length) {
			import(/*webpackChunkName: "masonry"*/ 'masonry-layout').then(({default:Masonry}) => {
				bricksEls.forEach((bricksEl, bricksIndex) => {

					if (!bricksEl.hasAttribute(initAttribute)) {
						bricksEl.setAttribute(initAttribute, '1');

						let bricks = new Masonry( bricksEl, {
							itemSelector: '.brick',
							columnWidth: '.brick-sizer',
							percentPosition: true,
							gutter: 32
							//horizontalOrder: true
						});
						bricksInstances.push(bricks);

						let resizeBricks = debounce(function() {
							if (bricks) {
								bricks.layout();
							}
						}, 1000, true);
						setTimeout(resizeBricks, 2500);
						setTimeout(resizeBricks, 5000);

						//listen for custom update events
						window.sitePubSub.subscribe('updateBricks'+bricksIndex, resizeBricks);
						subscriptions.push('updateBricks'+bricksIndex);

						//listen for video resizes
						window.sitePubSub.subscribe('responsiveVideosResized', resizeBricks);

						//listen for Instagram
						window.sitePubSub.subscribe('instagramInitialized', resizeBricks);

						//listen for Twitter
						window.sitePubSub.subscribe('twitterInitialized', resizeBricks);

						//listen for Facebook
						window.sitePubSub.subscribe('facebookInitialized', resizeBricks);

						//listen for iframe and image load events
						bricksEl.querySelectorAll('img, iframe').forEach(item => {
							listenerBoss.register(item,'load', ()=>{
								window.sitePubSub.publish('updateBricks'+bricksIndex);
							});
						});

						//watch for load events from new iframes and images
						if (typeof MutationObserver !== 'undefined') {
							/**
							 * The observer method. Watches for new elements that match the profile selectors.
							 * Processes the newbies and triggers an update.
							 * @type {MutationObserver}
							 */
							let observer = new MutationObserver(mutations => {
								//loop through each mutation
								mutations.forEach(mutation => {
									//check for added nodes
									if (mutation.addedNodes && mutation.addedNodes.length > 0) {
										//loop through added nodes
										mutation.addedNodes.forEach(addedNode => {
											//see if the mode matches the selector
											if (addedNode.nodeName) {
												let nodeName = addedNode.nodeName.toLowerCase();
												if (nodeName === 'iframe' || nodeName === 'img') {
													window.sitePubSub.publish('updateBricks'+bricksIndex);
													//listen for load events
													listenerBoss.register(addedNode,'load', ()=>{
														window.sitePubSub.publish('updateBricks'+bricksIndex);
													});
												} else if (nodeName === 'twitter-widget') {
													window.sitePubSub.publish('updateBricks'+bricksIndex);
												}
											}
										});
									}
								});
							});

							//pass in the target and observer options
							observer.observe(bricksEl, {attributes: false, childList: true, subtree: true, characterData: false});
						}
					}
				})
			});
		}
	},
	destroy() {
		listenerBoss.removeAll();

		bricksInstances.forEach(bricksInstance => {
			bricksInstance.destroy();

			subscriptions.forEach(subscription => {
				window.sitePubSub.unsubscribe(subscription);
			});
		});
	}
}