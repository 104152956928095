/**
 * ----- Drawers -----
 * Drawers are elements that can be toggled open or closed.
 * Drawers are designated with the following data attribute:
 * data-drawer="{drawerId}"
 *
 * The {drawerId} is the id of the drawer that will be used
 * to identify it to the drawer toggles.
 *
 * ----- Drawer Toggles -----
 * Drawer Toggles toggle drawers open or closed.
 *
 * Here are the attributes and what they do:
 * data-drawer-toggle="{drawerId}" - {drawerId} The id of the drawer(s) to toggle open and closed.
 * data-drawer-close="{drawerId}" - {drawerId} The id of the drawer(s) to toggle closed if the drawer is toggled open.
 * data-drawer-doc-class="{class}" - {class} The class to toggle on the document when the drawer is toggled.
 *
 * @author Aaron Waldon <aaron@causingeffect.com>, doc class param contribution by Michael Rog <michael@michaelrog.com>
 */

let htmlEl = document.documentElement;

/**
 * Polyfill for matches selector.
 *
 * @param element
 * @param selector
 * @returns {any}
 */
const matchesSelector = function(element, selector) {
    let matchesSelector = element.matches
        || element.matchesSelector
        || element.webkitMatchesSelector
        || element.mozMatchesSelector
        || element.msMatchesSelector
        || element.oMatchesSelector
        || null;
    return matchesSelector ? matchesSelector.call(element, selector) : false;
};

/**
 * Observes mutations.
 *
 * @param selector
 * @param callback
 */
const observeMutations = function (selector, callback) {
    if (typeof MutationObserver !== 'undefined' && typeof document.body !== 'undefined') {
        /**
         * The observer method. Watches for new elements that match the profile selectors. Processes
         * the newbies and triggers an update.
         * @type {MutationObserver}
         */
        let observer = new MutationObserver(function (mutations) {
            //loop through each mutation
            mutations.forEach(function (mutation) {
                //check for added nodes
                if (mutation.addedNodes && mutation.addedNodes.length > 0) {
                    //loop through added nodes
                    mutation.addedNodes.forEach(function (addedNode) {
                        //see if the mode matches the selector
                        let doesMatch = matchesSelector(addedNode, selector);
                        //handle matches
                        if (doesMatch) {
                            if(typeof callback === 'function') {
                                callback(addedNode);
                            }
                        }
                    });
                }
            });
        });

        //pass in the target and observer options
        observer.observe(document.body, {attributes: false, childList: true, subtree: true, characterData: false});
    }
};

export default {
    /**
     * Set up all of the drawer toggle elements.
     *
     */
    init(selector = '[data-drawer-toggle]', options = {}) {

        let toggleEls = document.querySelectorAll(selector);

        if (toggleEls.length) {
            toggleEls.forEach(toggleEl => this.addToggle(toggleEl, options));
        }

        observeMutations(selector,toggleEl => {
            this.addToggle(toggleEl, options);
        });
    },
    addToggle(toggleEl, {
            openedDrawerClass = 'drawer--open',
            closedDrawerClass = 'drawer--closed',
            openedToggleClass = 'toggle--open',
            closedToggleClass = 'toggle--closed',
            onOpen = function(toggleEl, drawerEl){},
            onClose= function(toggleEl, drawerEl){}
        } = {}) {

        //the document class (ads when opening, removes when closing)
        let docClass = toggleEl.getAttribute('data-drawer-doc-class');

        //get the drawers to toggle
        let toggleId = toggleEl.getAttribute('data-drawer-toggle');
        if (!toggleId) {
            return;
        }

        //get the drawers to toggle
        let toggleIdsSelectors = [];
        let drawersToToggleEls = [];
        let toggleIds = toggleId.split(' ');
        toggleIds.forEach(toggleId => {
            toggleIdsSelectors.push('[data-drawer~="'+toggleId+'"]');
        });
        drawersToToggleEls = document.querySelectorAll(toggleIdsSelectors.join(', '));

        //get the drawers to close and their respective togglers
        let closeId = toggleEl.getAttribute('data-drawer-close');
        let closeSelectors = [];
        let drawersToCloseEls = [];
        let closeToggleSelectors = [];
        let drawersToCloseToggleEls = [];
        if (!!closeId) {
            let closeIds = closeId.split(' ');
            closeIds.forEach(closeId => {
                closeSelectors.push('[data-drawer~="'+closeId+'"]');
            });
            drawersToCloseEls = document.querySelectorAll(closeSelectors.join(', '));

            //get the togglers for the drawers to close
            drawersToCloseEls.forEach((drawersToCloseEl) => {
                let closeDrawer = drawersToCloseEl.getAttribute('data-drawer');
                if (!!closeDrawer) {
                    closeDrawer.split(' ').forEach(drawerId => {
                        closeToggleSelectors.push('[data-drawer-toggle~="'+drawerId+'"]');
                    });
                }
            });
            drawersToCloseToggleEls = document.querySelectorAll(closeToggleSelectors.join(', '));
        }

        if (drawersToToggleEls.length) {
            toggleEl.addEventListener('click', e => {
                e.preventDefault();
                drawersToToggleEls.forEach((drawerEl) => {
                    if (drawerEl.classList.contains(openedDrawerClass)) { //close the drawer
                        //update the drawer classes
                        drawerEl.classList.add(closedDrawerClass);
                        drawerEl.classList.remove(openedDrawerClass);

                        //update the toggle classes
                        toggleEl.classList.add(closedToggleClass);
                        toggleEl.classList.remove(openedToggleClass);

                        //remove the body class
                        if (docClass) {
                            htmlEl.classList.remove(docClass);
                        }

                        if (typeof onClose === 'function') {
                            onClose(toggleEl, drawerEl);
                        }
                    } else { //open the drawer
                        //close any competing drawers
                        drawersToCloseEls.forEach((drawersToCloseEl) => {
                            drawersToCloseEl.classList.add(closedDrawerClass);
                            drawersToCloseEl.classList.remove(openedDrawerClass);
                        });
                        //update the competing drawers' toggles too
                        drawersToCloseToggleEls.forEach((drawersToCloseToggleEl) => {
                            drawersToCloseToggleEl.classList.add(closedToggleClass);
                            drawersToCloseToggleEl.classList.remove(openedToggleClass);
                        });

                        //update the drawer classes
                        drawerEl.classList.add(openedDrawerClass);
                        drawerEl.classList.remove(closedDrawerClass);

                        //update the toggle classes
                        toggleEl.classList.add(openedToggleClass);
                        toggleEl.classList.remove(closedToggleClass);

                        // Add the body class
                        if (docClass) {
                            htmlEl.classList.add(docClass);
                        }

                        if(typeof onOpen === 'function') {
                            onOpen(toggleEl, drawerEl);
                        }
                    }
                });
            });
        }
    }
};