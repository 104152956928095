const isDebugMode = false;
const delayTimeMs = window.hasOwnProperty('modalDelay') ? window.modalDelay : 3000;
const html = document.querySelector('html');
const htmlOpenClass = 'modalOpen';
const modalOptions = {
	disableScroll: false,
	awaitOpenAnimation: true,
	awaitCloseAnimation: true,
	onShow: () => html.classList.add(htmlOpenClass),
	onClose: () => html.classList.remove(htmlOpenClass),
};

//test local storage - see https://stackoverflow.com/a/11214467/1136822
const isSessionStorageSupported = function() {
	let test = 'test';
	try {
		sessionStorage.setItem(test, test);
		sessionStorage.removeItem(test);
		return true;
	} catch(e) {
		return false;
	}
}();

const init = function() {
	let modals = document.querySelectorAll('[data-modal]');
	if (modals.length) {
		import(/*webpackChunkName: "modal"*/ '../../scss/modal.scss').then(() => {
			import(/*webpackChunkName: "modal"*/ 'micromodal').then(({default:MicroModal}) => {
				modals.forEach(modal => {
					modal.removeAttribute('style');
					let modalName = modal.getAttribute('data-modal');
					if (isSessionStorageSupported) {
						if (isDebugMode || sessionStorage.getItem(modalName) !== 'y') {
							sessionStorage.setItem(modalName, 'y');
							window.setTimeout(function() {
								MicroModal.show(modalName, modalOptions);
							}, delayTimeMs);
						}
					} else {
						window.setTimeout(function () {
							MicroModal.show(modalName, modalOptions);
						}, delayTimeMs);
					}
				});
			});
		})
	}
};

export default init;
