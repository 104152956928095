import Sticky from 'sticky-js';

let sticky = false;

export default {
	init(selector = '[data-sticky]') {
		if (!!sticky) {
			sticky = null;
			sticky = false;
		}
		let els = document.querySelectorAll(selector);
		if (els && els.length) {
			sticky = new Sticky(selector);
		}
	},
	update() {
		if (!!sticky) {
			sticky.update();
		}
	}
}